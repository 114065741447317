<template>
  <a href="https://www.linkedin.com/in/ilia-sokolovskiy/">
    <img class="photo" alt="Welcoming Photo" src="./assets/cvSQ.png">
  </a>
  <HelloWorld msg="Welcome to my resume webpage!"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.photo {
  width: 20%;
  height: 20%;
  margin: 10px;
  border: 5px solid darkgoldenrod;
}
.photo:hover {
  opacity: 50%;
}
</style>
